import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject){
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => err);
}
Vue.use(VueRouter)

const routes = [
    { path: "/", redirect: "/trailer/1" },
    {
        path: "/trailer/:sid",
        name: "trailer",
        meta: { auth: false },
        component: () => import("../views/Trailer/Trailer.vue")
    }, 
    {
        path: "/serial/:sid",
        name: "serial",
        meta: { auth: true },
        component: () => import("../views/Serial/Serial.vue")
    },
    {
        path: "/mine",
        name: "mine",
        meta: { auth: true },
        component: () => import("../views/Mine/Mine.vue")
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.auth) {
        const token = localStorage.getItem('token');
        if (token) { next(); } else {
            next({
                path: '/trailer/1', query: { redirect: to.path }
            });
        };
    } else { next(); };
});

export default router
