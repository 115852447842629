import api from "@/services/api"
import router from '@/router/index';
import { Message } from 'element-ui';

const state = () => ({
    siginStatus: false,
    sigupStatus: false,
    coverStatus: false,
    token: null,
    mine: {},
    avatar: window.localStorage.getItem('avatar') || '1',
    edAvtStatus: false,
    edEmaStatus: false,
    edPwdStatus: false,
    vipStatus: false,
});

const mutations = {
    SET_SIGIN_STATUS(state, data) {
        state.siginStatus = data;
    },
    SET_SIGUP_STATUS(state, data) {
        state.sigupStatus = data;
    },    
    SET_COVER_STATUS(state, data) {
        state.coverStatus = data;
    },
    SET_TOKEN(state, data) {
        state.token = data;
    },
    SET_MINE(state, data){
        state.mine = data;
    },
    SET_AVATAR(state, data){
        window.localStorage.setItem('avatar', data);
        state.avatar = data;
    },
    SET_EDAVT_STATUS(state, data) {
        state.edAvtStatus = data;
    },
    SET_EDEMA_STATUS(state, data) {
        state.edEmaStatus = data;
    },
    SET_EDPWD_STATUS(state, data) {
        state.edPwdStatus = data;
    },
    SET_VIP_STATUS(state, data) {
        state.vipStatus = data;
    },
};

const actions = {    
    SetCoverStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
    },
    SetSiginStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_SIGIN_STATUS", data);
    },
    SetSigupStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_SIGUP_STATUS", data);
    },
    SetEdavtStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_EDAVT_STATUS", data);
    },
    SetEdemaStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_EDEMA_STATUS", data);
    },
    SetEdpwdStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_EDPWD_STATUS", data);
    },
    SetVipStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_VIP_STATUS", data);
    },
    AccountCheckRegemailFn({commit}, params){
        return api.accountCheckRegemail(params)
            .then(res => {
                const { status, message }  = res.data;
                if(status != 2) Message.error(message);
                return status;
            })
    },
    AccountSignUpFn({commit}, params){
        return api.accountSignUp(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const token = data.access_token;
                    commit("SET_TOKEN", token);
                    window.localStorage.setItem("token", token);
                    commit("SET_VIP_STATUS", true);
                    Message.success("Welcome to AOFlix~");
                }
                return code;
            })
    },
    AccountSignInFn({commit}, params){
        return api.accountSignIn(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const token = data.access_token;
                    commit("SET_TOKEN", token);
                    window.localStorage.setItem("token", token);
                    const signRoute = window.localStorage.getItem('path');
                    if(signRoute) { 
                        router.push(signRoute);
                        window.localStorage.removeItem('path');
                    }
                    Message.success("Sign in success");
                }
                return code;
            })
    },
    FetchUserInfoFn({commit}, params){
        return api.fetchUserInfo(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_MINE', data);
                }
                return code;
            })
    },
    EditEmailFn({commit}, params){
        return api.editEmail(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    Message.success("Edit success~");
                }
                return code;
            })
    },
    EditPasswordFn({commit}, params){
        return api.editPassword(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    Message.success("Edit success~");
                }
                return code;
            })
    },
    SetAvatarFn({ commit }, params) {
        commit('SET_AVATAR', params);
    },
    ResetAccountState({ commit }) {
        localStorage.token
            ? commit('SET_TOKEN', localStorage.token)
            : console.log("nothing to do");
    },
    
};

export default {
    state,
    mutations,
    actions
}
