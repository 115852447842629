<template>
    <div id="app">      
        <load-cover v-if="loadStatus"></load-cover>
        <section class="main" ref="main">
            <transition enter-active-class="animated fadeIn">
                <router-view></router-view>
            </transition>            
        </section>
        <wjs-footer id="footer"></wjs-footer>
        <section v-if="coverStatus" class="background-cover"></section>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import LoadCover from "@/components/Load/LoadCover";
import WjsFooter from "@/components/Common/WjsFooter";

export default {
    name: "application",
    computed: {
        ...mapState({
            loadStatus: (state) => state.serial.loadStatus,
            coverStatus: (state) => state.account.coverStatus,
        }),
    },
    created(){
        this.SubmitBasicInfoFn();
        this.ResetAccountState();

        if(document.body.clientWidth < 768) this.SetTrailerStatusFn("complex");
    },
    mounted(){
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    },
    methods: {
        ...mapActions({
            ResetAccountState: "ResetAccountState",
            SubmitBasicInfoFn: "SubmitBasicInfoFn",
            SetTrailerStatusFn: "SetTrailerStatusFn"
        }),
        handleResize() {
            this.$refs.main.style.minHeight =
                document.documentElement.clientHeight - 89 + "px";
        },
    },
    components: {
        LoadCover,
        WjsFooter
    }
};


</script>
