import axios from '@/utils/interceptor'

export default {

    // 检查注册邮箱
    accountCheckRegemail: function(payload){
        return axios.post("/api/register/check", {...payload});
    },

    // 注册
    accountSignUp: function(payload){
        return axios.post("/api/register", {...payload});
    },

    // 登录
    accountSignIn: function(payload){
        return axios.post("/api/auth/login", {...payload});
    },

    // 上报基础数据
    submitBasicInfo: function(payload = {}){
        return axios.post("/api/movie/info", {...payload});
    },

    // 上报影片数据
    submitMovieDetail: function(payload = {}){
        return axios.post("/api/movie/detail", {...payload});
    },

    // 获取个人信息接口
    fetchUserInfo: function(payload = {}){
        return axios.post("/api/auth/me", {...payload});
    },
    
    // 修改邮箱
    editEmail: function(payload = {}){
        return axios.post("/api/auth/user/editmail", {...payload});
    },

    // 修改密码
    editPassword: function(payload = {}){
        return axios.post("/api/auth/reset", {...payload});
    },
}