import axios from 'axios';

import store from '@/store'

import { Message } from 'element-ui';

axios.interceptors.request.use(config => {
	
    const token = localStorage.getItem('token');

    if(token) config.headers['authorization'] = `Bearer ${token}`;

    config.data.lang = 'en';

    config.baseURL = process.env.NODE_ENV_BASEURL;

    return config;

}, error => {

    return Promise.reject(error);

});


axios.interceptors.response.use(response => {

    const { data, status} = response;

    if(status != 200) Message.error(response.statusText);

    if(data.code == 401){

        Message.error("安全令牌已过期，请重新登录");

        store.commit("SET_COVER_STATUS", true);

        store.commit("SET_SIGIN_STATUS", true);

    }else{

        if(data.code != 200) Message.error(data.msg);

    }
    

    return response.data;

}, error => {
    
    console.log(error)
    
});

export default axios;


