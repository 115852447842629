import api from "@/services/api"

const state = () => ({
    loadStatus: false,
    trailerStatus: "simple"
});

const mutations = {
    SET_LOAD_STATUS(state, data) {
        state.loadStatus = data;
    },
    SET_TRAILER_STATUS(state, data) {
        state.trailerStatus = data;
    },
};

const actions = {
    SetLoadStatusFn({commit}, data){
        commit("SET_LOAD_STATUS", data);
    },
    SetTrailerStatusFn({commit}, data){
        commit("SET_TRAILER_STATUS", data);
    },
    SubmitBasicInfoFn({commit}, params){
        return api.submitBasicInfo(params)
            .then(res => {
                const { code } = res;
                if(code == 200){
                    console.log("Basic submit");
                }
                return code;
            })
    },
    SubmitMovieDetailFn({commit}, params){
        return api.submitMovieDetail(params)
            .then(res => {
                const { code } = res;
                if(code == 200){
                    return code;
                }                
            })
    },

};

export default {
    state,
    mutations,
    actions
}
