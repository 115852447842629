import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/interceptor'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import {Utils} from './utils/utils.js'
import '@/assets/css/common.css'
import animated from 'animate.css'

Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.$video = Video;
Vue.prototype.$utils = Utils;
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
