class UtilClass {
    
    constructor() {

        this.data = [
            {
                sid: 1, 
                nav: "Breaking Bad Boy",
                name: "《Breaking Bad Boy》",
                release: "2022",
                producter: "AOFlix",
                total: 5,
                star: "star5",
                desc: "As a high school teacher, Walter developed a new drug can make peoples get high with enhance libido. So Walter asked Jesse to continue to help him sell the drug.                Walter wants to help Jesse get over the pain from his dead girlfriend. They made an appointment with Nathan, a local drug dealer. They were ready to do a big job in the drug market, but unexpectedly Nathan died after trying their new drugs...",
                trailer: {
                    web: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8",
                    mob: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8"
                },
                videos: [
                    {
                        cover: "1/1",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220922/OGQ5Nzc0Zj/233218/720/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/2",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/ZTVjNzFiY2/170125/1080/hls/decrypt/index.m3u8",
                    },                  
                    {
                        cover: "1/3",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/NTliYjEwMD/173507/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/4",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/MjcxMmE5Mj/100137/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/5",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/ZmQ0MWE2Mm/095820/1080/hls/decrypt/index.m3u8",
                    },
                ]
            },
            // {
            //     sid: 2, 
            //     nav: "乡村爱情故事",
            //     name: "《乡村爱情故事》之性爱篇",
            //     release: "2022",
            //     producter: "污剧社",
            //     total: 5,
            //     star: "star5",
            //     desc: "剧情简介：离经叛道是最好的爱抚，打破陈规是最爽的亲昵，阴谋诡计是最棒的催情剂，绝命毒师性爱篇将完美展现。",
            //     videos: [ // 预告片放置第一位
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         },
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         },
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         },                  
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         },
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         }
            //     ]
            // },
        ]

    }

    initOptions(videoUrl, autoplay, muted){
        const options = {
            autoplay: autoplay, // 如果true,浏览器准备好时开始播放。
            muted: muted, // 默认情况下将会消除任何音频。
            loop: true, // 导致视频一结束就重新开始。
            preload: 'auto', // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',  //汉化
            fluid: true, // 当true时，播放器将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
                type: 'application/x-mpegURL',
                src: videoUrl  //视频播放地址
            }],
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
            errorDisplay: false,  //错误显示
            posterImage: false,  //视频的预览海报图片显示
            bigPlayButton: false,  //视频中间的播放按钮显示
            textTrackDisplay: false
        };

        return options;
    };   

    checkSignInHandler(forms){
        if(!forms.email){
            return "Please enter user name";
        } else if (!forms.password){
            return "Please enter password";
        } else {
            return "success";
        }
    };

    checkSignUpHandler(forms){

        const valid_map = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;

        if(!forms.email){
            return "Please enter user name";
        } else if (!valid_map.test(forms.email)){
            return "Please check the email format";
        } else if (!forms.password){
            return "Please enter password";
        }  else if (forms.password.length < 6 || forms.password.length > 12){
            return "Please enter a 6-12 digit password";
        } else if (forms.password !== forms.rePassword){
            return "The two entered passwords do not match";
        } else {
            return "success";
        }
    };

    checkEditEmailHandler(forms){

        const valid_map = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;

        if(!forms.email){
            return "Please enter user name";
        } else if (!valid_map.test(forms.email)){
            return "Please check the email format";
        } else {
            return "success";
        } 
    };

    checkEditPasswordHandler(forms){
        if(!forms.oldpassword){
            return "Please enter old password";
        } else if (!forms.password){
            return "Please enter a new password";
        } else if (forms.password.length < 6 || forms.password.length > 12){
            return "Please enter a 6-12 digit password";
        } else if (forms.password !== forms.password_confirmation){
            return "The two entered passwords do not match";
        } else {
            return "success";
        }
    };
}

export const Utils = new UtilClass();