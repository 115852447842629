<template>
    <section class="loading-main">
        <div class="Loader">
            <div class="Bar"></div>
            <div class="Bar"></div>
            <div class="Bar"></div>
            <div class="Bar"></div>
            <div class="Bar"></div>
            <div class="Bar"></div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style scoped>
.loading-main {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 8888;
}
.hbd-loading {
    /* padding: 80px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; */
    padding: 80px;
}
.Loader {
    height: 30px;
    width: 90px;
    position: relative;
    margin: 0 auto;
}
.Loader .Bar {
    background: #d50d1f;
    height: 0;
    position: absolute;
    bottom: 0;
    width: 10px;
    border-radius: 2px;
}
.Loader .Bar:nth-child(0) {
    left: -15px;
    -webkit-animation: bottom-top 2s infinite ease-in-out 0s;
    animation: bottom-top 2s infinite ease-in-out 0s;
}
.Loader .Bar:nth-child(1) {
    left: 0px;
    -webkit-animation: bottom-top 2s infinite ease-in-out 0.2s;
    animation: bottom-top 2s infinite ease-in-out 0.2s;
}
.Loader .Bar:nth-child(2) {
    left: 15px;
    -webkit-animation: bottom-top 2s infinite ease-in-out 0.4s;
    animation: bottom-top 2s infinite ease-in-out 0.4s;
}
.Loader .Bar:nth-child(3) {
    left: 30px;
    -webkit-animation: bottom-top 2s infinite ease-in-out 0.6s;
    animation: bottom-top 2s infinite ease-in-out 0.6s;
}
.Loader .Bar:nth-child(4) {
    left: 45px;
    -webkit-animation: bottom-top 2s infinite ease-in-out 0.8s;
    animation: bottom-top 2s infinite ease-in-out 0.8s;
}
.Loader .Bar:nth-child(5) {
    left: 60px;
    -webkit-animation: bottom-top 2s infinite ease-in-out 1s;
    animation: bottom-top 2s infinite ease-in-out 1s;
}
.Loader .Bar:nth-child(6) {
    left: 75px;
    -webkit-animation: bottom-top 2s infinite ease-in-out 1.2s;
    animation: bottom-top 2s infinite ease-in-out 1.2s;
}
h4 {
    text-align: center;
    padding-top: 40px;
}

.night h4 {
    color: #fff;
}

@-webkit-keyframes bottom-top {
    0% {
        height: 0;
        opacity: 0;
    }
    50% {
        height: 30px;
        opacity: 1;
    }
    100% {
        height: 0;
        opacity: 0;
    }
}

@keyframes bottom-top {
    0% {
        height: 0;
        opacity: 0;
    }
    50% {
        height: 30px;
        opacity: 1;
    }
    100% {
        height: 0;
        opacity: 0;
    }
}
</style>